import React, { CSSProperties } from 'react';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import type { GatsbyImageProps } from 'gatsby-plugin-image';

interface IFluidImage extends GatsbyImageProps {
  fit?: CSSProperties['objectFit']
  src?: string
  aspectRatio?: number
  className?: string
}

const FluidImage = ({
  alt,
  fit,
  image,
  src = '',
  className = '',
  aspectRatio = 1,
  ...props
}: IFluidImage) => {
  const localImage = getImage(image);
  const percentageRatio = `${((1 / aspectRatio) * 100).toString()}%`;

  if (!localImage && !src) return null;

  return (
    <div
      className={`relative h-0 overflow-hidden ${className}`}
      style={{ paddingTop: percentageRatio, background: 'white' }}
    >
      {localImage ? (
        <GatsbyImage
          className="absolute inset-0"
          objectFit={fit || props.objectFit}
          style={{ position: 'absolute' }}
          image={localImage}
          alt={alt}
        />
      ) : (
        <img
          className="absolute inset-0 w-full h-full object-cover"
          src={src}
          alt={alt}
        />
      )}
    </div>
  );
};

FluidImage.defaultProps = {
  src: '',
  fit: 'contain',
  aspectRatio: 1,
  className: '',
};

export default FluidImage;
