import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Theme, ThemeVariant } from '@websites/model';
import { ThemeContext } from '@websites/components';
import { parse } from 'query-string';
import {
  isClient,
  log,
  serverBusinessToClientBusiness,
} from '@websites/utils';

import slugify from 'slugify';

const defaultValues = {
  yellowBusiness: {},
  sections: [],
};

export const YellowBusinessContext = React.createContext(defaultValues);

const businessQuery = graphql`
  query MyQuery {
    yellowBusiness: yellowBusiness {
      business {
        assets {
          caption
          contentUrl
          classification
          type
        }
        details {
          name
          description
          sameAs {
            name
            url
          }
          logo
        }
        providerConfiguration {
          # websites channel-specific details
          yellowWebsite {
            googleSiteVerificationId
            alertBar {
              link
              text
            }
            alternativeHeadline
            headline
            slug
            heroHeaderType
            slideshow {
              buttonLabel
              buttonLink
              desktopContentPosition
              headline
              headlineSize
              link
              subheadline
              slidshowGatsbyImage {
                childImageSharp {
                  gatsbyImageData(
                    width: 2160
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            customDomain
            primaryImageOfPage
            gatsbyImagePrimaryImageOfPage {
              childImageSharp {
                gatsbyImageData(
                  width: 2160
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
            gallery
            galleryGatsbyList {
              galleryGatsbyImage{
                childImageSharp {
                  gatsbyImageData(
                    width: 500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
        locations {
          address {
            administrativeArea
            locality
            postalCode
            streetName
            streetNumber
            sublocality
          }
          default
          email
          hasMap
          showOnWebsite
          geo {
            latitude
            longitude
          }
          openingHours {
            opens
            closes
            dayOfWeek
            conditionsOfAccess
          }
          mobileNumber {
            areaCode
            number
          }
          telephone {
            areaCode
            number
          }
        }
        products {
          name
          description
          image
          gatsbyImageImage {
            childImageSharp {
              gatsbyImageData(
                width: 400
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
        }
      }
    }
  }
`;

export const YellowBusinessProvider = ({ children }) => {
  const queryResult = useStaticQuery(businessQuery);
  const serverBusiness = queryResult?.yellowBusiness?.business;

  const queryParams = isClient() ? parse(window.location.search) : {};
  const [previewDataFromIFrameParent, setPreviewDataFromIFrameParent] = useState();

  const clientBusiness = serverBusinessToClientBusiness({
    serverBusiness,
    defaultTheme: ThemeVariant.tradie,
  });

  const [yellowBusiness, setYellowBusiness] = useState({
    ...clientBusiness,
  });

  useEffect(() => {
    if (yellowBusiness && queryParams?.theme && queryParams?.theme !== `${process.env.GATSBY_WEBSITES_THEME}`) {
      const newBusiness = { ...yellowBusiness };
      newBusiness.website.theme = queryParams.theme;
      setYellowBusiness(newBusiness);
    }
  }, [queryParams]);

  useEffect(() => {
    if (yellowBusiness && previewDataFromIFrameParent) {
      const iframeBusinessAsClientBusiness = serverBusinessToClientBusiness({
        serverBusiness: previewDataFromIFrameParent,
        defaultTheme: ThemeVariant.tradie,
      });
      const newBusiness = {
        ...yellowBusiness,
        ...iframeBusinessAsClientBusiness,
      };
      setYellowBusiness(newBusiness);
    }
  }, [previewDataFromIFrameParent]);

  useEffect(() => {
    const eventHandler = (event) => {
      log('received message from iframe', event);
      if (event.isTrusted && event.data?.details) {
        log('message valid and will be applied', event.data);
        setPreviewDataFromIFrameParent(event.data);
      }
    };
    window.addEventListener('message', eventHandler);
    return () => {
      window.removeEventListener('message', eventHandler);
    };
  }, []);

  let servicesSection = null;
  if (yellowBusiness?.products?.length) {
    servicesSection = {
      name: 'Products & Services',
      link: `/#${slugify('Products & Services', { lower: true })}`,
    };
  }

  let aboutBusinessSection = null;
  if (yellowBusiness?.description) {
    aboutBusinessSection = {
      name: 'About us',
      link: `/#${slugify('About us', { lower: true })}`,
    };
  }

  let gallerySection = null;
  if (yellowBusiness?.gallery?.length) {
    gallerySection = {
      name: 'Gallery',
      link: '/gallery',
    };
  }

  let locationSection = null;
  const locationsToShowOnWebsite = yellowBusiness?.locations?.filter((location) => location.showOnWebsite);
  if (locationsToShowOnWebsite?.length) {
    const name = locationsToShowOnWebsite?.length > 1 ? 'Locations' : 'Find us';
    locationSection = {
      name,
      link: '/location',
    };
  }

  const contactBusinessSection = {
    name: 'Get in touch',
    link: '/contact',
  };

  const sections = {
    aboutBusinessSection,
    servicesSection,
    gallerySection,
    locationSection,
    contactBusinessSection,
  };

  return (
    <>
      {yellowBusiness?.website?.theme && (
        <ThemeContext.Provider value={new Theme(yellowBusiness?.website?.theme)}>
          <YellowBusinessContext.Provider
            value={{
              ...defaultValues,
              yellowBusiness,
              sections,
            }}
          >
            {children}
          </YellowBusinessContext.Provider>
        </ThemeContext.Provider>
      )}
    </>
  );
};
